.dish-modal-form .highlight label > span {
  background-color: #ffe1b5;
}

.dish-modal-form label .ant-btn {
  padding: 0;
  margin-left: 5px;
}

.dish-modal-form .ant-form-item-label > label::after {
  content: none;
}

.dish-modal-form .highlight-blue .ant-select-selector,
.dish-modal-form input.highlight-blue {
  border-color: #1890ff !important;
}

.dish-modal-form .highlight-yellow .ant-select-selector,
.dish-modal-form .highlight-yellow.ant-tag {
  background-color: #f5ffbc;
}

.dish-modal-form span.highlight-blue {
  margin-right: 0.5rem;
}

.dish-modal-form .highlight-blue.exact .ant-select-selector {
  border-color: rgba(0, 255, 0, 0.5);
}
.dish-modal-form span.highlight-blue.exact:first-child {
  background-color: rgba(0, 255, 0, 0.5);
}

.dish-modal-form .highlight-blue.replacement .ant-select-selector {
  border-color: #d6a2bc;
}
.dish-modal-form span.highlight-blue.replacement:first-child {
  background-color: #d6a2bc;
}

.dish-modal-form .highlight-blue.synonym_level_1 .ant-select-selector,
.dish-modal-form .highlight-blue.variety_level_1 .ant-select-selector,
.dish-modal-form .highlight-blue.synonym .ant-select-selector,
.dish-modal-form .highlight-blue.variety .ant-select-selector {
  border-color: rgba(195, 0, 255, 0.2);
}
.dish-modal-form span.highlight-blue.synonym_level_1:first-child,
.dish-modal-form span.highlight-blue.synonym:first-child,
.dish-modal-form span.highlight-blue.variety_level_1:first-child,
.dish-modal-form span.highlight-blue.variety:first-child {
  background-color: rgba(195, 0, 255, 0.2);
}

.dish-modal-form .highlight-blue.synonym_level_2 .ant-select-selector,
.dish-modal-form .highlight-blue.variety_level_2 .ant-select-selector {
  border-color: rgba(195, 0, 255, 0.4);
}
.dish-modal-form span.highlight-blue.synonym_level_2:first-child,
.dish-modal-form span.highlight-blue.variety_level_2:first-child {
  background-color: rgba(195, 0, 255, 0.4);
}

.dish-modal-form .highlight-blue.mlSuggested .ant-select-selector {
  border-color: #ffdb40;
}

.dish-modal-form span.highlight-blue.mlSuggested:first-child {
  background-color: #ffdb40;
}

.dish-modal-form .highlight-blue.synonym_level_3 .ant-select-selector,
.dish-modal-form .highlight-blue.variety_level_3 .ant-select-selector {
  border-color: rgba(195, 0, 255, 0.8);
}
.dish-modal-form span.highlight-blue.synonym_level_3:first-child,
.dish-modal-form span.highlight-blue.variety_level_3:first-child {
  background-color: rgba(195, 0, 255, 0.8);
}

.dish-modal-form .highlight-blue.near .ant-select-selector {
  border-color: rgba(0, 255, 255, 0.8);
}
.dish-modal-form span.highlight-blue.near:first-child {
  background-color: rgba(0, 255, 255, 0.8);
}

.dish-modal-form .highlight-blue.alternativeTypeMatch .ant-select-selector,
.dish-modal-form .highlight-blue.ant-tag {
  border-color: #ada9fc;
}

.dish-modal-form span.highlight-blue.alternativeTypeMatch:first-child {
  background-color: #ada9fc;
}

.dish-modal-form .highlight-orange .ant-select-selector {
  border-color: #ff9f00 !important;
}

.locationDropdown ul {
  max-height: none;
  overflow: hidden;
}

.locationDropdown > div > div {
  overflow: hidden !important;
  height: 100%;
}

.locationDropdown .ant-select-dropdown-menu-item-selected {
  background-color: #e6f7ff;
}
