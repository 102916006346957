.brand-entry .highlight-blue .ant-select-selection,
.brand-entry .highlight-blue .ant-select-selector,
.brand-entry .ant-tag.highlight-blue,
.brand-entry input.highlight-blue {
  border-color: #1890ff;
}

.brand-entry .highlight-green .ant-select-selection,
.brand-entry .highlight-green .ant-select-selector,
.brand-entry .ant-tag.highlight-green,
.brand-entry input.highlight-green {
  border-color: #044d02;
}

.brand-entry .highlight-red div.ant-select-selector,
.brand-entry input.highlight-red,
.brand-entry button.highlight-red {
  border-color: #ff0000;
}
