.dishes-tables-ingredients-popover .ant-popover-inner-content {
  padding: 0;
}

.dishes-tables-ingredients-popover .ant-table-cell {
  padding: 3px 8px !important;
}

.dishes-tables-ingredients-popover .ant-table {
  margin: 0 !important;
}
