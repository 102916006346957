.task-list-hotkeys-legend .ant-modal-body {
  padding: 0 8px;
}

.task-list-hotkeys-legend .ant-table-cell {
  padding: 3px 8px !important;
}

.task-list-hotkeys-legend .ant-table {
  margin: 0 !important;
}
