.diet-collapse.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  left: 0;
}

.diet-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-left: 1rem;
}

.diet-collapse.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  border-left: 1px solid rgb(235, 235, 235);
  margin-left: 6px;
}
