.menuTitleRow {
  background-color: white;
}

.menuTitleRow-suggested {
  background-color: rgb(255, 245, 230);
}

.menuTitleRow-legend-highlight {
  background-color: #ffd6e7;
}

.menuTitleRow span[style*='font-style: italic'],
.menuTitleRow-suggested span[style*='font-style: italic'] {
  font-weight: 900;
}

.menu-title-table.highlight-red {
  border: 1px solid #ff0000;
}
