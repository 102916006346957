.optionToBeCurated {
  background-color: #ffe1b5;
}

.optionToBeCurated.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.optionToBeCurated.ant-select-dropdown-menu-item-active {
  background-color: #ffc6a2;
}

.error .ant-select-selection {
  border-color: #f5222d;
  color: #f5222d;
}

.error.ant-select-focused .ant-select-selection {
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
}

.optionWithError {
  color: #f5222d;
}
.optionWithError i {
  margin-right: 5px;
}
