.clickable {
  cursor: pointer;
}

.dish-name-col:hover {
  cursor: pointer;
  text-decoration: underline;
}

.curated-row {
  background-color: #f5f5f5;
}

.curated-row .ant-table-cell-fix-left {
  background-color: #f5f5f5;
}

.uncurated-row {
  background-color: #ffffff;
}

.uncurated-row .ant-table-cell-fix-left {
  background-color: #ffffff;
}

.curation-row {
  background-color: #fff5e6;
  height: 100%;
}

.curation-row .ant-table-cell-fix-left {
  background-color: #fff5e6;
}

.curation-cell {
  background-color: #ffe1b5;
}

.cell-low-certainty {
  background-color: #ffedd0 !important;
}

.cell-error,
.error-row {
  background-color: #ffd6e7 !important;
}

.cell-qa-correction {
  background-color: #f5e5e6 !important;
}

.cell-editable {
  padding: 0;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #fafafa;
}

.ignored-row {
  background-color: #a3a3a3;
}

.ignored-row .ant-table-cell-fix-left {
  background-color: #a3a3a3;
}

.error-row .ant-table-cell-fix-left {
  background-color: #a3a3a3;
}

.dish-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 5px;
}

.big-checkbox .ant-checkbox-checked .ant-checkbox-inner,
.big-checkbox .ant-checkbox .ant-checkbox-inner {
  width: 2rem;
  height: 2rem;
}

.big-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  width: 0.5rem;
  height: 1rem;
}

.ant-table-tbody > tr > td {
  transition: none !important;
}

.selected-row td {
  background-color: #fcf7c8 !important;
}

.selected-cell {
  border: 1px solid #222 !important;
}

.selected-row.border-highlight td {
  border-top: 1px solid #222 !important;
  border-bottom: 1px solid #222 !important;
}

.selected-row.border-highlight td:first-child {
  border-left: 1px solid #222 !important;
}

.selected-row.border-highlight td:last-child {
  border-right: 1px solid #222 !important;
}

.dark-dropdown-highlights
  .ant-select-tree
  .ant-select-tree-treenode-active
  .ant-select-tree-node-content-wrapper,
.dark-dropdown-highlights
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #dedede !important;
}
