.brand-suggested-words-modal .ant-modal-body {
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
}

.brand-suggested-words-modal .ant-modal-body .ant-row {
  flex: 1;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.brand-suggested-words-modal .ant-modal-body .ant-pagination {
  align-self: flex-end;
}

.brand-suggested-words-modal .ant-select.highlight-red .ant-select-selector,
.brand-suggested-words-modal .ant-input.highlight-red {
  border-color: #ff0000;
}
