.ant-input div.DraftEditor-editorContainer,
.ant-input div.public-DraftEditor-content {
  height: 100%;
}

.ant-input.input .public-DraftEditor-content {
  height: 43px;
  overflow: auto hidden;
  padding-bottom: 10px;
}

.ant-input.input .public-DraftStyleDefault-block {
  white-space: pre;
}

.disabledRichInput {
  max-width: 100%;
}

.disabledRichInput:focus {
  outline: none;
}

.disabledRichInput span[style*='font-style: italic'] {
  font-weight: 900;
}

.ant-input .public-DraftEditor-content span[style*='font-style: italic'] {
  font-weight: 900;
}

.richTextContainer div:nth-child(2) {
  z-index: 100 !important;
}
