.dish-finished-curating .ant-modal-content,
.dish-finished-curating .ant-modal-header {
  background-color: #f5f5f5;
}

.dish-not-finished-modal .ant-modal-content {
  background-color: #fff8ee;
}

.dish-not-finished-modal .ant-modal-header {
  background-color: #fff8ee;
}

.dish-ignored-modal .ant-modal-content {
  background-color: #a3a3a3;
}

.dish-ignored-modal .ant-modal-header {
  background-color: #a3a3a3;
}

.dishModal .ant-modal-close .ant-modal-close-x {
  width: 0;
  height: 0;
}

.dish-not-finished-modal {
  overflow: hidden;
}

.dishModal .ant-modal-body {
  padding-top: 0;
}

.dish-error-modal .ant-modal-content,
.dish-error-modal .ant-modal-header {
  background-color: #ffd6e7;
}
